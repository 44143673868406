.dashboard {
  display: flex;
  padding: 30px 40px;
}

.dashboard nav {
  display: flex;
  flex-direction: column;

  width: 15%;
  margin-right: 2%;
}

.dashboard nav button {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  padding: 5px 10px;

  display: flex;
  align-items: center;

  font-size: 18px;
  font-weight: 600;
}

.dashboard nav button svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.dashboard .panel {
  width: 83%;
  display: flex;
  flex-direction: column;
}

.dashboard .panel .banner {
  position: relative;
  height: 300px;

  background-color: #61c6ff;
  border-radius: 5px 5px 0 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-bottom: 20px;
}

.dashboard .panel .banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dashboard .panel .banner h1 {
  font-size: 30px;
  color: #fff;
  margin-bottom: 40px;
}

.dashboard .panel .banner .types {
  display: flex;
  align-items: center;
  gap: 20px;
}

.dashboard .panel .banner .types .type {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  cursor: pointer;
}

.dashboard .panel .banner .types .type .icon {
  background-color: #fff;
  padding: 10px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard .panel .banner .types .type:hover .icon {
  background-color: rgba(255, 255, 255, 0.7);
}

.dashboard .panel .banner .types .type .icon svg {
  width: 30px;
  height: 30px;
  fill: #fff;
}

.dashboard .panel .banner .types .type h2 {
  font-size: 18px;
  color: #fff;
}

.dashboard .panel .incards h1 {
  margin-bottom: 20px;

  font-size: 20px;
  color: #303030;
}

.dashboard .panel .incards .cards-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 20px;
}

.dashboard .panel .incards .cards-list .card {
  cursor: pointer;
  position: relative;
  color: #303030;
  text-decoration: none;
}

.dashboard .panel .incards .cards-list .card .img {
  background-color: rgba(200, 200, 200, 0.2);
  padding: 20px 20px 0 20px;
  border-radius: 7px;

  width: 200px;
  height: 170px;
  overflow: hidden;

  margin-bottom: 10px;
}

.dashboard .panel .incards .cards-list .card .img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.dashboard .panel .incards .cards-list .card h2 {
  font-size: 18px;
}

.dashboard .panel .incards .cards-list .card h3 {
  font-size: 13px;
}
